* {
  margin: 0 auto;
  outline: none;
  padding: 0;
}

:root {
  --white: #fff;
  --lighter-grey: #f7f7f7;
  --accent-grey: #cfcece;
  --light-grey: #e5e5e5;
  --dark-grey: #606161;
  --green-t1: #49cabe;
  --green-t2: #76dec7;
  --green-t3: #28bc9b;
  --green-t4: #05b08a;
  --green-t5: #008668;

  --red-t4: #f3072c;
  --red-t5: #c4001f;

  --blue-t3: #376eba;
  --blue-t4: #0b428e;
  --blue-t5: #07336f;

  --component-radius: 25px;
  --component-radius-secondary: 15px;
  --component-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  --component-shadow-secondary: 0 0 16px rgba(0, 0, 0, 0.09);
  --component-shadow-hover: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
  --component-scale-hover: scale(1.002);
  --input-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  --dashboard-menu-width: 300px;
  --dashboard-main-width: calc(100% - calc(var(--dashboard-menu-width) + 25px));
}

body,
html {
  background: var(--accent-grey);
  font-family: sans-serif;
  background: var(--blue-t5);
}

body.login {
  height: 100%;
  overflow: hidden;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: #a9a9a9;
}

/**
** @ Inline Elements
**/
.form-btn span,
.main-form-btn,
.pf-left,
.pf-right,
.dashboard-menu,
.dashboard-title,
.dm-left,
.dm-right,
.sentiment-source,
.overall-sentiment,
.source-item,
.dc-left,
.tt-left,
.tt-right,
.tt-right-total,
.menu-topic-icon,
.menu-topic-label,
.menu-topic-go,
.social-header-icon,
.social-header-label,
.data-link-left,
.data-link-icon,
.user-profile-left,
.user-profile-right,
.add-user-left,
.add-user-right,
.pagination button,
.pagination span {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}

.hidden {
  opacity: 0;
}

.popup-text {
  font-size: 16px;
  padding-bottom: 45px;
}

.page-wrap {
  position: relative;
  width: 100%;
  min-height: 750px;
}
