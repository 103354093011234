.user {
  &-component {
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    color: var(--dark-grey);

    &.chart-component {
      min-height: 0;
    }
  }

  &-manager {
    padding: 15px 0;
    text-align: right;
  }
}

.status-label,
.search-label {
  padding: 5px 10px;
  color: var(--white);
  font-size: 12px;
  background: var(--green-t3);
  border-radius: var(--component-radius);
}

.status-label {
  display: block;
  padding: 0;
  color: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0;

  &.inactive {
    background: var(--accent-grey);
  }
}

.search-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background: var(--blue-t3);
  margin-top: 5px;

  &.add {
    opacity: 0;
    border-radius: 50%;
    background: var(--green-t3);
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .global-table-component tr:hover & {
      transition: 400ms;
      opacity: 1;
    }
  }
}
