.user-add-wrap {
  background: rgba(0, 0, 0, 0.36);
}

.add-user {
  &-box {
    width: 80vw;
    z-index: 9999;
  }

  &-left,
  &-right {
    width: 50%;
    vertical-align: top !important;
    padding: 0 15px;
  }
}

.permissions {
  text-align: left;
  margin: 0 15px 12px;
  padding: 15px;
  border-radius: var(--component-radius);
  box-shadow: var(--input-shadow);
  font-size: 16px;

  & .form-check {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & input {
    width: 40px;
    text-align: center;
    margin-bottom: 10px;
    border: 1px solid var(--accent-grey);
    border-radius: var(--component-radius-secondary);
  }
}
